@tailwind base;
@tailwind components;

body {
    cursor: url('/images/cursor.svg?v=1'), auto;
}

@keyframes beat {
    50% {
        transform: scale(0.75);
        opacity: 0.2;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

svg {
    display: inline-block;
}

@keyframes animatedBackground {
    0% {
        transform: translateX(1px);
        opacity: 0.7;
        filter: blur(20px);
    }
    20% {
        transform: translateX(2px);
        opacity: 0.9;
        filter: blur(16px);
    }
    50% {
        transform: translateX(3px);
        opacity: 0.4;
        filter: blur(18px);
    }
    80% {
        transform: translateX(2px);
        opacity: 0.9;
        filter: blur(16px);
    }
    100% {
        transform: translateX(1px);
        opacity: 0.8;
        filter: blur(20px);
    }
}


@tailwind utilities;
